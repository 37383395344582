import React, {useState, useEffect} from 'react';
const axios = require('axios');
const server = 'https://search-admin.planview.com/'


const Account = (props) => {
  return (
    <section className="account">
      <div className="container">
        safd
      </div>
    </section>
  );
}

export default Account;