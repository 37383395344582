import React, {useState, useEffect, useRef} from 'react';
import AddToCalendar from '@culturehq/add-to-calendar';
import Calendar from 'react-calendar';
import { useCookies } from 'react-cookie';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import './custom.scss';
import 'react-calendar/dist/Calendar.css';
import '@culturehq/add-to-calendar/dist/styles.css';
const axios = require('axios');
 
const server = 'https://search-admin.planview.com/'
const FBToken = `EAAH8IIsOLqUBACYUi8Fy69MAOzlLTT78H8nNR59kqDFZCUGRU79K4fbZCgByPh2z85fL8GlsLH2JSxlYTJtUYDa3tvFvXAXnaY4SgSrVZBGc5ixh3P35pQAvZA8HDAvH3Oy8gZA0fdtbZCxCbTGimMFKjTCzDzAZBZC8XMNZAgSfVg3RTd0sWlPVr`

const App= (props) => {
  const bookingRef = useRef(null)
  const OFFSET = '.000-05:00'  

  Date.prototype.stdTimezoneOffset = function () {
    var jan = new Date(this.getFullYear(), 0, 1);
    var jul = new Date(this.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  }

  Date.prototype.isDST = function () {
      return this.getTimezoneOffset() < this.stdTimezoneOffset();
  }

  const [view, setView] = useState('booking')
  const [cookies, setCookie] = useCookies(['home-salon']);
  const [invalid, setInvalid] = useState({name: null, phone: null, address: null, time: null, service: null})
  const [confirmDate, setConfirm] = useState();
  const [slide, setSlide] = useState(1);
  const [times, setTime] = useState('');
  const [location, setLocation] = useState(false);
  const [reviews, setReview] = useState([])
  const [moreReviews, setMoreReview] = useState(false)
  const [user, setUser] = useState([])
  const [modalSrc, setModalSrc] = useState(null)
  const [phone, setPhone] = useState()
  const [list, setList] = useState([
    {time: '9:00 AM', good: 1, value: 9, selected: false},
    {time: '10:00 AM', good: 1, value: 10, selected: false},
    {time: '11:00 AM', good: 1, value: 11, selected: false},
    {time: '12:00 PM', good: 1, value: 12, selected: false},
    {time: '1:00 PM', good: 1, value: 13, selected: false},
    {time: '2:00 PM', good: 1, value: 14, selected: false},
    {time: '3:00 PM', good: 1, value: 15, selected: false},
    {time: '4:00 PM', good: 1, value: 16, selected: false},
    {time: '5:00 PM', good: 1, value: 17, selected: false},
    {time: '6:00 PM', good: 1, value: 18, selected: false},
    // {time: '7:00 PM', good: 1, value: 19, selected: false}
  ])
  const [services, setService] = useState([
    {type: "Woman Cut", selected: false, price: '$25', p: 25, duration: 1},
    {type: "Woman Cut - Long Hair", selected: false, price: '$35', p: 35, duration: 1},
    {type: "Woman Bob-Cut", selected: false, price: '$35', p: 25, duration: 1},
    {type: "Woman Cut & Style - Short Hair", selected: false, price: '$35', p: 35, duration: 1},
    {type: "Woman Cut & Style - Long Hair", selected: false, price: '$45', p: 45, duration: 2},
    {type: "Woman Cut & Curl", selected: false, price: '$45', p: 45, duration: 1},
    {type: "Man Cut", selected: false, price: '$25', p:25, duration: 1},
    {type: "Boy Cut ( age 1- 12 )", selected: false, price: '$15', p:15, duration: 1},
    {type: "Girl Cut ( age 1- 12 )", selected: false, price: '$15', p:15, duration: 1},
    {type: "Blow Dry ( Short Hair )", selected: false, price: '$25', p:25, duration: 1},
    {type: "Girl Cut ( age 1- 12, long & thick)", selected: false, price: '$25', p:25, duration: 1},
    {type: "Blow Dry ( Long Hair )", selected: false, price: '$35', p:35, duration: 2},
    {type: "Hair Perm", selected: false, price: 'N/A', p:35, duration: 2},
    {type: "Other Service", selected: false, price: 'N/A', p: -10000, duration: 0}, 
  ])
  const [busy, setBusy] = useState(false)
  const [date, setDate] = useState('')
  const [info, setSession] = useState(null)
  // const [photos, setPhotos] = useState({prev: null, next: `https://graph.facebook.com/v11.0/121631386856687/photos?fields=webp_images&limit=8&access_token=${FBToken}`, pics: []})
  const [photos, setPhotos] = useState({ next: `https://graph.facebook.com/v11.0/109609611387321/posts?fields=attachments&limit=4&access_token=${FBToken}`, pics: {}})
  const [event, setEvent] = useState({service: services[0].type, location: ""})
  const [total, setTotal] = useState(0)
  const [bntPorfolio, setbntPorfolio] = useState(false)
  const [duration, setDuration] = useState(0)
  const [notAvailableMsg, setNotAvalialbeMsg] = useState(false)
  const [reminder, setReminder] = useState({
    name: "Home Salon Service", 
    details: "",
    location: "",
    startsAt: "2018-12-06T17:00:00-05:00",
    endsAt: "2018-12-06T18:00:00-05:00"
  })
  const {id} = props.match.params

  const onSelectDay = (date) => {
    let dt = new Date(date)
    let month = dt.getMonth() + 1;
    let day = dt.getDate()
    if (day < 10){
      day = `0${day}`
    }
    if (month < 10) {
        month = `0${month}`;
    }
    let chosenDate = `${dt.getFullYear()}-${month}-${day}`
    setDate(chosenDate)
    setBusy(true)
    axios.post(`${server}salon`, {
      action: 'today_appointment',
      today: { 
        "start": `${chosenDate}T00:00:00${OFFSET}`,
        "end":   `${chosenDate}T23:59:00${OFFSET}`
    }
    }).then((res)=>{
      // console.log(res.data)
      let temp = list.map((item) => {item.good = 1; return item })
      let today = new Date()
      today.setTime(today.getTime() + 1*60*60*1000) // only can book in 2 hours
      if (dt.getFullYear() === today.getFullYear() && dt.getMonth() === today.getMonth() && dt.getDate() === today.getDate()){
        temp.forEach(e => {
          if (e.value <= today.getHours()){
            e.good = -1;
            return;
          }
        })
      }
      
      res.data.forEach(item => {
        if (item.status === "confirmed"){
          let d = new Date(Date.parse(item.start.dateTime))
          let d2 = new Date(Date.parse(item.end.dateTime))
          temp.forEach(e => {
  
            if (e.value >= d.getHours() && e.value < d2.getHours()){
              if (item.summary && item.summary.toLowerCase().includes('block')){
                e.good = -1;
              }else{
                e.good = 0;
              }
              return;
            }
          })
        }

      });
      let availables = temp.filter(item => item.good > 0)
      if (availables.length <= 0) {
        setNotAvalialbeMsg(dt.toLocaleDateString('en-US'))
      }else{
        setNotAvalialbeMsg(false)
        setList(temp)
        setSlide(2)
        bookingRef.current.scrollIntoView() 
      }
      setBusy(false)
    }).catch(err => {
      console.log(err)
      setBusy(false)
    })
  }
  
  const notAvailable = ({activeStartDate, date, view }) => {
    let today = new Date(),
        c_date = new Date(date.getTime())
        c_date.setHours(date.getHours() + 23)
    let diffTime = c_date - today,
        diffDays = (Math.ceil(diffTime / (1000 * 60 * 60 * 24)))
    return c_date.getTime() <= today.getTime() || diffDays > 60  || c_date.getDay() === 0
  }
  const getReview= () => {
    axios.post(`${server}salon`, {
      action: 'get_review'
    }).then((res)=>{      
      if(res.data.length > 0) {
        setReview(res.data)
      }
    }).catch(err => {
      console.log(err)
    })
  }
  const getSession = () => {
    let userTemp = cookies['home-salon']
    if (userTemp){
      axios.post(`${server}salon`, {
        action: 'user',
        user: userTemp.id
      }).then((res)=>{
        if ( res.data.history.length > 0 ){
          let pending =res.data.history.filter(item => {
            let dt =  new Date(Date.parse(item.start.dateTime)),
            today = new Date()
            return dt > today
          })
          setUser(pending)
        }
        if( res.data.info){
          let session = res.data.info
          // console.log(session)
          session['name'] = session.name.replace(' haircut', '')
          event['phone'] = userTemp.id
          event['name'] = session.name
          event['location'] = session.address !== 'Cut at my home' ? session.address : ''
          setLocation(session.address !== 'Cut at my home')
          setPhone(session.phone)
          setSession(session)
          setEvent(event)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
  const getPhotos = (url) => {
    setbntPorfolio(true)
    axios.get(url)
    .then(function (res) {
      let pics = photos.pics
      // console.log(res.data)
      if (res.data.data.length > 0) {

        res.data.data.forEach( p => {

          let attachments = p.attachments
          if ( attachments ) {
            let sub_attachments = attachments.data[0].subattachments
            let key = p.id
            // console.log(key)
            let page = [];
            if (sub_attachments) {
              sub_attachments.data.forEach(item => {
                page.push(item.media.image.src)
              })
            }else{
              if (attachments.data[0].media){
                page.push(attachments.data[0].media.image.src)
              }
            }if (page.length > 0) {
              pics[key] = page
              setPhotos({
                next: res.data.paging.next, 
                pics
              });
            }
          }
        })
        
      }      
      setbntPorfolio(false)
    }).catch(err => {
      console.log(err)
      setbntPorfolio(false)
    })
  }
  const saveData = (value, target) => {
    event[target] = value
    setEvent(event)
  }
  const serviceSelect = (item) => {
    let temp1 = {...invalid}
    temp1.service = null
    setInvalid(temp1)

    let temp = [...services]
    temp.forEach(e => {
      if (e.type === item.type){
        e.selected = !e.selected
      }
    }) 
    setService(temp)    
  }
  const onSelectTime = (selectedTime) => {  
    let temp1 = {...invalid}
    temp1.time = null
    setInvalid(temp1)
    
    let temp = [...list]
    temp.forEach(e => {
      if (e.value === selectedTime.value){
        e.selected = !e.selected
      }
    }) 
    let choosingtime = temp.filter(item => item.selected)
    let start = choosingtime.length > 0 ? choosingtime[0] : []
    let end = choosingtime.length > 0 ? choosingtime.pop() : []
    let current = null
    temp.forEach(e => {
      if (e.value >= start.value && e.value <= end.value){
        if( e.good > 0){
          e.selected = true
          e.range = (e.value === start.value) ? 'start' : (e.value === end.value) ? 'end' : 'middle'
          current = e
        }else{
          e.selected = false
          e.range = ''
          current.range = 'end'
          end = current
        } 
      }else{
        e.selected = false
        e.range = ''
      }
    }) 
    setDuration((end.value + 1) - start.value)
    setList(temp)
  }
  const clearTimeSelection = () =>{
    let temp = [...list]
    temp.forEach(e => {
      e.selected = false
      e.range = ''
    }) 
    setList(temp)
    setDuration(0)
  }
  //----------- api -------------
  useEffect(()=>{
    getSession()
    getReview()
    getPhotos(photos.next)      
  },[id])
  const goBack = (panel) => {
    
    let tempList = [...list]
    tempList.forEach(item => {
      item.selected = false
    })
    setList(tempList)
    let temp = {...invalid}
    temp.time = null
    setInvalid(temp)
    setSlide(1)
  }
  const adjustDST = (time) => {
    let timeStr =  `T${(time < 10 ? `0${time}` : time)}:00:00${OFFSET}`
    let dt = new Date(Date.parse(`${date}${timeStr}`))
    if (!dt.isDST()){ // Daylight saving start 03/14 - 11/7
      time++;
      return `T${(time < 10 ? `0${time}` : time)}:00:00${OFFSET}`
    }
    return timeStr
  }
  const gotoSubmit = () =>{
    let invalids = {...invalid}
    let timeSlots = list.filter(item => item.selected)
    let serviceSlots = services.filter(item => item.selected)
    let tt = serviceSlots.reduce((acc, item) => acc + item.p, 0)
    setTotal(tt)

    invalids.time = timeSlots.length > 0
    invalids.service = serviceSlots.length > 0
    setInvalid(invalids)
    // console.log(invalids)
    if (invalids.time && invalids.service){
      let start = timeSlots[0]
      let end = timeSlots.pop()
      saveData(`${tt > 0 ? `Total: $${tt}`: ''} ${serviceSlots.map(item => item.type)}`, 'service');
      setTime({ 
        startTime: adjustDST(start.value), 
        endTime: adjustDST(end.value + 1) 
      })
      setSlide(3)
      bookingRef.current.scrollIntoView() 
    }
  }

  const onSubmit = () => {
    let invalids = {...invalid},
        bookDate = new Date(Date.parse(`${date}${times.startTime}`))
        
    invalids.name = event.name ? true : false
    invalids.phone = phone ? true : false
    if (location){
      if (event.location){
        invalids.address = true
      }else{
        invalids.address = false
      }
    }else{
      invalids.address = true
    }
    setInvalid(invalids)
    setBusy(true)
    if( invalids.name && invalids.phone && invalids.address ){  
      let serviceSlots = services.filter(item => item.selected)   
      let eventParam = {
        summary: `${event.name} haircut`,
        phone,
        description: `Phone: ${phone} \nType: ${event.service} \nDetails: ${event.description || 'N/A'}`,
        location: location ? event.location : 'Cut at my home',
        start: {
          'dateTime': `${date}${times.startTime}`,
          'timeZone': 'America/Chicago'
        },
        end: {
          'dateTime': `${date}${times.endTime}`,
          'timeZone': 'America/Chicago'
        },
        extendedProperties: {
          private: {
            phone,
            services: serviceSlots.map(item => item.type),
            description: event.description || 'N/A',
            total
          }
        }
      }
      setReminder({
        name: "Home Salon Service",
        details: `${event.service} \nDetails: ${event.description || 'N/A'}`,
        location: location ? event.location : '100 Firwood S, Kyle, TX 78640',
        startsAt: `${date}${times.startTime}`,
        endsAt: `${date}${times.endTime}`
      })
      // console.log(eventParam)
      // setConfirm({day: bookDate.toLocaleDateString('en-US'), time: bookDate.toLocaleTimeString('en-US', {
      //   hour: '2-digit',
      //   minute:'2-digit',
      //   timeZone: 'America/Chicago'
      // })})
      //   setCookie('home-salon', JSON.stringify({id: phone}), {path: '/'}) 
      //   setBusy(false)

      axios.post(`${server}salon`, {
        action: 'create',
        event: eventParam
      }).then((data)=>{
        // console.log(data)
        setConfirm({day: bookDate.toLocaleDateString('en-US'), time: bookDate.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute:'2-digit',
          timeZone: 'America/Chicago'
        })})
        setCookie('home-salon', JSON.stringify({id: phone}), {path: '/'})
        user.push(eventParam)
        setUser(user)
        setBusy(false)
        bookingRef.current.scrollIntoView() 
      }).catch(err => {
        console.log(err)
        setBusy(false)
      })
    }else{
      setBusy(false)
    }
  }
  
  return (
    <div id="calendar">
      <section ref={bookingRef} className="booking">
        <div className="container">
          <div className="inner">
            {
              view === 'history' &&
              <div className="history">
                <button onClick={() => {setView('booking')}} className="close-history">X</button>
                <h2>Your pending reservation</h2>
                <ul>
                  {
                    user.map((item, i) => (
                      <li key={i}>
                        <a href={`/reservation/${item.id}`}>
                          {(new Date(Date.parse(item.start.dateTime))).toLocaleDateString('en-US')} {(new Date(Date.parse(item.start.dateTime))).toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute:'2-digit'
                          })}
                        </a>
                      </li>
                    ))
                  }
                </ul>
              </div>
            }
            {
              view === 'booking' &&
              <div className={`calendar ${confirmDate ? 'confirm' : ''} ${busy? 'busy': ''}`}>
                { user.length > 0 &&
                  <button onClick={() => {setView('history')}} className="user"><i className="fa fa-calendar"></i><span>{user.length}</span></button>
                }
                <h2 className="l3-heading">Make reservation for your haircut.</h2>                
                <p>
                  My service only for Buda and Kyle area.<br />
                  <small>Any change to your reservation, please messenger me at least one day before your appointment.</small>
                  </p>
                <div className="slides">
                  <div className={`slide date-slide ${slide === 1 ? 'active': ''}`}>
                    <p>Pick your day<i>*</i></p>
                    {
                      notAvailableMsg && 
                      <span class="no-available-msg">Sorry {notAvailableMsg} is all booked!</span>
                    }
                    <Calendar
                      onChange={onSelectDay}
                      tileDisabled= {notAvailable}      
                      calendarType="US"             
                      />
                  </div>
                  <div className={`slide time-slide ${slide === 2 ? 'active': ''}`}>
                    <button className="btnBack" onClick={() => goBack(1)}><i className="fa fa-arrow-left"></i> Back</button> 
                    <p>What service/s?<i>*</i></p>
                    <div className={`services ${invalid.service === null || invalid.service === true ? '' : 'invalid'}`}>
                      {
                        services.map((item,i) => (
                          <span key={i} onClick={() => serviceSelect(item) } className={`service ${item.selected ? `checked` : ''}`}>{item.type} <small>{item.p > 0 ? `(${item.price})` : ''}</small></span>
                        ))
                      }
                    </div>
                    <hr />
                    <div className="timeslot">
                      <p>Book a time slot<i>*</i><br/><small>(You can select multiple slots if need-be)</small></p>
                      <button className="btnClear" onClick={clearTimeSelection}>Clear time selection</button>
                      <div className={`times ${invalid.time === null || invalid.time === true ? '' : 'invalid'}`}>
                        {
                          list.map((item, i) => (
                              <button key={i} className={`${item.good === 1 ? '' : (item.good === -1 ? 'passed' : 'booked')} ${item.selected ? 'selected' : ''} ${item.range || ''}`} onClick={() => {onSelectTime(item)}}>{item.time}</button>
                          ))
                        }
                      </div>
                      {
                        duration > 0 &&
                        <p className="duration">You've selected time of {`${duration} hour${duration > 1? 's' : ''}`} for your service to finish.</p>
                      }
                    </div>
                    {
                      (invalid.service === false || invalid.time === false) &&
                      <div className="error-msg">
                        <span>{invalid.service === false ? 'Service is not selected.' : ''}</span> 
                        <span>{invalid.time === false ? 'Time slot is not selected.' : ''}</span>
                      </div>
                    }
                    <button className="button" onClick={gotoSubmit}>Next step</button>
                  </div>
                  <div className={`slide location-slide ${slide === 3 ? 'active': ''}`}>
                    
                    <h3>{info ? 
                      <>
                        Welcome back {info.name}! 
                        <button className="btnNotYou" onClick={()=> setSession(null)}>(Not you)</button>
                      </> : `A little bit about You`
                    }</h3>
                    <button className="btnBack" onClick={() => setSlide(2)}><i className="fa fa-arrow-left"></i> Back</button>
                    { !info &&
                      <>
                        <div className={`field ${invalid.name === null || invalid.name === true ? '' : 'invalid'}`}>
                          <label>Your Name<i>*</i></label>
                          <em>(Prefer your Facebook name for verification purpose)</em>
                          <input type="text" onChange={(e) => saveData(e.target.value, 'name')} />
                        </div>
                        <div className={`field ${invalid.phone === null || invalid.phone === true ? '' : 'invalid'}`}>
                          <label>Phone Number<i>*</i></label>
                          <PhoneInput
                            placeholder="Enter phone number"
                            defaultCountry="US"
                            value={phone}
                            onChange={setPhone}/>
                        </div>
                      </>
                    }

                    <label><i className="fa fa-car"></i> Should I go to your place?</label>
                    <div className="field radio">
                      <label onClick={() => setLocation(true)} className={location ? `checked` : ''}>Yes</label>
                      <label onClick={() => setLocation(false)} className={location ? '' : `checked`}>No</label>
                    </div>
                    
                    {
                      location &&
                      <div className={`field ${invalid.address === null || invalid.address === true ? '' : 'invalid'}`}>
                        <label>Enter your address<i>*</i></label>
                        <small className="highlight">This option offers to Sunfield community, senior citizen, and my previous customers only. (There will be an extra $10 fee)</small>
                        <input type="text" defaultValue={event.location} placeholder="Your address" onChange={(e) => saveData(e.target.value, 'location')} />
                      </div>
                    }
                    <div className="field">
                      <label>Is there something you want to tell me?</label>
                      <textarea onChange={(e) => saveData(e.target.value, 'description')} rows="4"></textarea>
                    </div>
                    {
                      (invalid.name === false || invalid.phone === false || invalid.location === false) &&
                      <div className="error-msg">
                        <span>{invalid.name === false ? 'Name is blank.' : ''}</span> 
                        <span>{invalid.phone === false ? 'Phone is blank.' : ''}</span>
                        <span>{invalid.address === false ? 'Address is blank.' : ''}</span>
                      </div>
                    }
                    <button className="button primary large" onClick={onSubmit}>Book Now</button>
                  </div>
                </div>                                    
                
                {
                  confirmDate &&
                  <div className="confirm-box">
                    <div className="modal-body">
                      <h2>Thank you, {event.name} for choosing our haircut service.</h2>
                      <p>Your reservation on <strong>{confirmDate.day}</strong> at <strong>{confirmDate.time}</strong> is confirmed.</p> 
                      <p>{total > 0 ? <strong>{`Total due at confirmation: $${total}`}</strong> : ''}. Payment will be collecting after the service either by Venmo or cash.</p>
                      {!location &&
                        <p className="address-confirm">Please write down my address<br /> <strong>100 Firwood South<br />Kyle, TX 78640</strong><br />Cell: 512-552-2267</p>
                      }
                      <AddToCalendar event={reminder} />
                      <p>We will text or private message (Facebook Messenger) your confirmation shortly.</p>
                      <div className="shoutout">
                        <img src="./images/shoutout.png" alt=""/>
                        <p>Please spreading love, Home Salon can keep continue to serve a safe and affordable haircut to our community during this difficult time.</p>
                        <button className="button" onClick={()=> window.location.reload()}>Finish</button>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
            {/* <video preload="auto" width="100%" autoPlay playsInline loop muted>
                <source src="https://firebasestorage.googleapis.com/v0/b/beauty-hair-319217.appspot.com/o/salon.m4v?alt=media&token=d921d327-483d-436f-8149-e6ed6d2d5e64" type="video/mp4" />
              </video> */}
            <img src="/images/location.jpg" />
            <div className="headline">            
              <h1>Home Salon, Your Personal Stylist</h1>
              <p>Where you can be yourself!</p>
              {/* <p>Home Salon is a convenient way to get a great hairstyle and safe at an affordable price in your community.</p> */}
            </div>
          </div>
        </div>
      </section>
      <section id="pricing" className="pricing">
        <div className="container">          
          <h2>Our Pricing</h2>
          <p><small>Payment will be collecting after service is done, you can pay us with Venmo, Zelle, or cash.</small></p>
          <table>     
            <thead>
              <tr>
                <th>Service</th>
                <th>Price</th>

              </tr>
            </thead>
            <tbody>
              {
                services.map((item, i) => (
                  <tr key={i}>
                    <td>{item.type}</td>
                    <td>{item.price}</td>

                  </tr>
                ))
              }
            </tbody>     
          </table>          
        </div>
      </section>
      <section className="covid">
        <div className="container">
          <h2>Covid 19 Information</h2>
          <p>I am fully vaccinated and wearing mask for entire time of servicing.</p>
        </div>
      </section>
      <section id="reviews" className={`reviews ${moreReviews ? 'show-more': ''}`}>
        <div className="container">
          <h2 className="l2-heading">Hear what my neighbors say about my service.</h2>
          <div className='feature-wrapper'>
            <img src='/images/feature1_censored.jpg' alt='' />
            <img src='/images/feature2_censored.jpg' alt='' />
          </div>
          <ul>
            {
              reviews.map((item, i) => (                
                <li key={i}>
                  <i className="fa fa-quote-right"></i>
                  <p>&ldquo;{item.review}&rdquo;</p>
                  <span className="author">{item.name}</span>
                </li>
              ))
            }
          </ul>
          <button className='btn-more' onClick={() => setMoreReview(!moreReviews)}>{moreReviews ? 'Show less' : 'More Reviews'}</button>
        </div>
      </section>
      <section id="portfolio" className="flexslider carousel">
        <div className="container">
          <h2>My Portfolio</h2>
          <ul className="slides">
            {
              Object.entries(photos.pics).map(([k, item], i) => (
                <li key={i}>
                  {
                    item.map((img, l) => (
                      <img key={l} src={img} loading="lazy" alt="" onClick={(e) => setModalSrc(img)} />
                    ))
                  }
                </li>
              ))
            }
          </ul>
          <button className={`btn-more ${photos.next ? '' : 'disable'} ${bntPorfolio ? 'busy' : ''}`} onClick={() => getPhotos(photos.next)}>Show More</button>
        </div>
      </section>
      <section id="about" className="bio">
        <div className="container">
          <h2>A Bit About Me</h2>
          <img src="/images/hairdresser.jpg" alt="" loading="lazy" />
          <p>Sievmey started hair styling in Belgium for 6+ years, after moving to Austin TX in 2013, she continue her work at a well-known salon at Slaughter LN until 2019.</p>
          <p>With pendamic rampage across the states, she decied to quit and become a home mom for 3 children. Her passion about hair styling, she found an opportunity to serve her beloved Sunfield community. </p>
          <p><strong>"I wanted to make people beautiful, so I became a hairstylist"</strong> &ndash; Sievmey</p>
        </div>
      </section>    
      <section id="contact">
        <div class="container">
          <a href="https://facebook.com/msg/109609611387321" target='_blank'><img src="./images/messenger.jpg" />Click here to messenger me.</a>
        </div>
      </section>  
      <section id="location">
        <div className='container'>
          <h2>My Location</h2>
          {
            info &&
            <p>100 Firwood South, Kyle TX 78640, or click this link <a href='https://maps.google.com/?q=100+Firwood+S,+Kyle,+TX+78640' target="_blank">Open Navigation</a></p>
          }
          {
            !info &&
            <p>We are locating in front of <strong>Homedepot Kyle Texas</strong>, the address will be provided after booking.</p>
          }
          <div className='row'>
            <div className='col'>
              <img src="/images/location.jpg" />
            </div>
            <div className='col'>
              <iframe src={ info ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3454.4158008948125!2d-97.84397738488535!3d30.024926781889327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865b538f1902c92d%3A0x8b35a205945d5865!2s100%20Firwood%20S%2C%20Kyle%2C%20TX%2078640!5e0!3m2!1sen!2sus!4v1643221340409!5m2!1sen!2sus" : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6908.449988261579!2d-97.84780064378326!3d30.03040236375223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865b53927f7e2a2f%3A0x5b7135a6a031c20f!2sAmberwood%20S%2C%20Kyle%2C%20TX%2078640!5e0!3m2!1sen!2sus!4v1642443529047!5m2!1sen!2sus"} allowFullScreen="" loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </section>
      {
        modalSrc &&
        <section className="modal" onClick={() =>  setModalSrc(null)}>
          <div className="container">
            <button className="closeModal"><i className="fa fa-close"></i></button>
            <img src={modalSrc} alt=""/> 
          </div>
        </section>
      }
    </div>
  );
};
 
export default App;