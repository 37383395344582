import React, {useState, useEffect} from 'react';
import { Profiler } from 'react';
const axios = require('axios');
const server = 'https://search-admin.planview.com/'


const Dashboard = (props) => {
  const [review, setReview] = useState({name: "", review: ''})
  const [users, setUsers] = useState([])
  const [editUser, setEditUser] = useState(null)
  const [selectedPage, setPage] = useState()
  const [user, setUser] = useState(null)
  const {page} = props.match.params
  useEffect(()=>{
    setPage(page)

    axios.post(`${server}salon`, {
      action: 'user_all'
    }).then((res)=>{
      setUsers(res.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
    }).catch(err => {
      console.log(err)
    })

  },[page])

  const saveData = (value, target) => {
    review[target] = value
    setReview(review)
  }
  const saveReview = () => {
    if (review.name && review.review){
      axios.post(`${server}salon`, {
        action: 'create_review',
        review
      }).then((res)=>{      
        if(res.data > 0) {
          window.location.reload()
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
 
  const logUser = () => {
    if (user.name){
      user['log']= editUser
      setUser(user)
      axios.post(`${server}salon`, {
        action: 'user_update',
        user
      }).then((res)=>{
        setUser(null)
        setEditUser(null)
      }).catch(err => {
        console.log(err)
      })
    }
  }
  return (
    <section className="dashboard">
      <div className="container">
        {
          page === 'review' &&
          <div className="review">
            <h3>Review</h3>
            <div className="field">
              <label>Name</label>
              <input type="text" onChange={(e) => saveData(e.target.value, 'name')} />
            </div>
            <div className="field">
              <label>Review</label>
              <textarea rows={10} onChange={(e) => saveData(e.target.value, 'review')}></textarea>
            </div>
            <button className="button" onClick={saveReview}>Save</button>
          </div>
        }
        {
          !page &&
          <div className="log">
            <ul id="clients">
              {
                users.map((item, i) => (
                  <li key={i} onClick={() => {setUser(item); setEditUser(item.log)}}>{item.name.replace(' haircut', '')}</li>
                ))
              }
            </ul>
            <div className="log-area">
              {
                user &&
                <>
                  <label>{user.name.replace(' haircut', '')}</label>
                  <textarea rows={5} onChange={(e) => setEditUser(e.target.value)} value={editUser || ''}></textarea>
                  <button className="button" onClick={logUser}>Log</button>
                </>
              }
            </div>  
          </div>
        }
      </div>
    </section>
  )
}

export default Dashboard;