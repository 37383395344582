import React from "react";
import App from './App'
import Dashboard from './dashboard'
import Account from './my-account'
import Privacy from './privacy'
import { Route, BrowserRouter as Router } from 'react-router-dom';

function Salon() {
  return(
    <Router>
      <Route exact path="/" component={App} />
      <Route path="/reservation/:id?" component={Account} />
      <Route path="/dashboard/:page?" component={Dashboard} />
      <Route exact path="/privacy-policy/" component={Privacy} />
    </Router>
  )
}
export default Salon; 